@import (reference) '../../../../stylesheets/util/_mixins.less';

.top-nav-message--active {
  #root-nav {
    .nav__container.affix {
      top: inherit;
      position: inherit;
    }
  }
}

.isPostAuthNav3Shown {
  @media (min-width: @screen-md) {
    padding-left: @vertical-nav-width;
  }
}

.postAuthNav3PageBackground {
  @media (min-width: 1920px) {
    background-color: var(--background-container, @color-black-005);
  }
}
.postAuthNav3PageWrapper {
  background-color: var(--background-screen, @color-white);
  @media (min-width: 1920px) {
    margin-left: 0;
    max-width: 1920px;
    border-right: var(--border-element, 1px solid @color-black-030);
  }
}

.pageMinHeight {
  min-height: 100vh;
}
