@import (reference) "~@zola/zola-ui/src/styles/common/_variables.less";

.swatches {
  display: flex;
  align-items: center;
  padding: 0 10px 10px;
  .swatches-more-link {
    margin-left: 4px;
    font-size: 12px; // TODO: Use zola-ui var
  }
}
