@import (reference) '../../../../stylesheets/core';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.swatch {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;

  &.is-interactive {
    cursor: pointer;
  }

  // small by default
  width: 20px;
  height: 20px;
  padding: 2px;
  &.is-active {
    border: solid 1px @color-black-100;
  }

  &.medium {
    width: 28px;
    height: 28px;
    margin: 1px;

    &.is-active {
      border: solid 1px @color-black-100;
      padding: 2px;
    }
  }

  img,
  .swatch__inner {
    border-radius: 50%;
    cursor: pointer;
    vertical-align: baseline;
    border: 1px solid @color-outline-gray;
  }

  img,
  .swatch__inner {
    width: 100%;
    height: 100%;
  }

  .swatch__inner {
    &.Black {
      background-color: #1a170c;
    }

    &.Grey {
      background-color: #afb0ae;
    }

    &.White {
      background-color: @color-white;
      border: solid 1px @outline-gray;
    }

    &.Brown {
      background-color: #7a5547;
    }

    &.Beige {
      background-color: #f0e8df;
    }

    &.Green {
      background-color: #44b04c;
    }

    &.Blue {
      background-color: #2998e9;
    }

    &.Purple {
      background-color: #7f73c0;
    }

    &.Red {
      background-color: #d0222f;
    }

    &.Pink {
      background-color: #f693bd;
    }

    &.Yellow {
      background-color: #fcd656;
    }

    &.Orange {
      background-color: #ff9a00;
    }

    &.Multi {
      background-image: url('../../../../assets/images/shop/rainbow.png');
    }
  }
}
